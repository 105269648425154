#resultWrapper {
    position: fixed;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
}

.standardsResult {
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition-duration: 1s;
}

.standardsContentContainer {
    width: 100%;
    height: 500px !important;
    background-color: white;
    position: absolute;
    top: 380px;
    z-index: 2;
    transform: translateY(-50%);
    height: fit-content;
    /* background-image: url('../../images/Card_Result_Active.svg'); */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0 !important;
    color: black;
    transition-duration: 1s;
}

#backdrop {
    width: 200vw;
    position: fixed;
    height: 200vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}
 
#standardName {
    font-size: 1em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    padding: 5% 5% 1% 5%;
} 

#subTitle {
    font-size: 1.2em;
    font-weight: 250;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    padding: 0 5% 5% 5%;
    height: 120px;
    overflow: auto;
} 

#description {
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 7em 0 7em;
    text-align: left;
    height: 170px;
    overflow: auto;
}

#URLDiv {
    text-align: center;
    /* margin-bottom: 50px; */
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    /* margin: 0 15% 0 15%; */
    border-radius: 8px;
    height: 2em;
    width:10em;
    transition-duration: 0.5s;
}

#URLDiv:hover {
    background-color: grey;
    transition-duration: 0.5s;
    cursor: pointer;
}

#URL {
    width: 100%;
    margin-top: 33%;
    color: white;
    font-size: 1em;
    font-weight: bold;
    font-variant: small-caps;
}

#resultTop {
    width: 100%;
    /* height: 70px; */
    position: absolute;
    z-index: 2;
    /* top: -60px; */
    /* opacity:0; */
    /* display: none; */
}

@media only screen and (max-width: 1070px) {
    #resultTop {
        height: 75px;
    }
}
@media only screen and (max-width: 960px) {
    #resultTop {
        height: 80px;
    }
}
@media only screen and (max-width: 850px) {
    #resultTop {
        height: 90px;
    }
}
@media only screen and (max-width: 768px) {
    #resultTop {
        height: 84px;
    }
}
@media only screen and (max-width: 740px) {
    #resultTop {
        height: 70px;
    }
}
@media only screen and (max-width: 640px) {
    #resultTop {
        height: 80px;
    }
}
@media only screen and (max-width: 576px) {
    #resultTop {
        height: 70px;
    }
}
@media only screen and (max-width: 530px) {
    #resultTop {
        height: 80px;
    }
}
@media only screen and (max-width: 420px) {
    #resultTop {
        height: 90px;
    }
    #topicsHeader {
        margin-left: 1em !important;
    }
}

#closeStandard {
    width: 2.5em;
    height: 2.5em;
    padding-top: 10px;
    padding-right: 10px;
    position: absolute;
    font-size: larger;
    color: gray;
    font-weight: bold;
    right: 2%;

    text-align: right;
    /* background-color: white; */
}

#closeStandard:hover {
    cursor: pointer;
}

#titleAndSubtitle {
    background-color: rgb(231, 231, 231);
    margin: 5% 5em 4% 5em;
    border-radius: 15px;
}

@media only screen and (max-width: 768px) {
    .standardsNavigationContainer {
        display: none;
    }
}