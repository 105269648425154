#CTASection {
    display: none;
}

.CTARow {
    margin-top: 5em;
    margin-bottom: 3em;
}

#CTAContent {
    background-color: white;
    border-radius: 15px;
    color: black;
    text-align: left;
    padding: 1em 3em;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

#CTAContent h4 {
    font-size: 1.25em;
    font-weight: 00;
    background-color: rgb(236, 236, 236);
    border-radius: 15px;
    padding: 20px;
}

#initialCTAHeader {
    margin-right: 3em;
}

#CTASectionDescription {
    background-color: white;
    height: fit-content;
    padding: 1em 1em 6em 1em;
    margin-right: 1em;
    margin-top: 0em;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#CTAContent p {
    font-size: 1em;
    font-weight: 300;
    padding: 0 10% 0 10%;
}

#DescriptionBottomBox {
    width: 100%;    
    padding: 0em 1em 7em 0em;
}

#GetInvolvedTopBox {
    width: 100%;
    margin: 0;
    padding: 0;

}

#GetInvolvedTopBoxContainer {
    margin: 0;
}

/* iPhone X */
@media only screen and (max-width: 415px) and (max-height: 812px) {
    #CTASectionDescription {
        margin-left: 0em !important;
        margin-bottom: 1em !important;
        height: 25vh !important;
    }
    #GetInvolvedTopBoxContainer {
        margin-top: 1em !important;
        margin-left: 1em !important;
        padding: 0em !important;
    }
    #DescriptionBottomBox {
        display: none !important;    
    }
    .TopicSection {
        margin-top: 2em;
        margin-bottom: 3em;
    }
}
/* iPad */
@media only screen and (max-width: 767px) {
    .CTARow {
        margin-top: 2em;
        margin-bottom: 3em;
    }
    #CTASectionDescription {
        margin-left: 0em !important;
        margin-bottom: 1em !important;
        height: 25vh !important;

    }
    #DescriptionBottomBox {
        display: none !important;    
    }
    #GetInvolvedTopBoxContainer {
        margin-top: 1em !important;
        margin-left: 1em !important;
        padding: 0em !important;
    }
}

/* iPad pro */
@media only screen and (max-width: 1024px) and (max-height: 1366px) {
    #CTASectionDescription {
        margin-left: 1em !important;
        margin-bottom: 0em !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
    }
    #CTASectionBox {
        margin-bottom: 0em !important;
    }
    #GetInvolvedTopBoxContainer {
        margin-right: 1em !important;
        margin-left: 1em !important;
    }
    #DescriptionBottomBox {
        visibility: hidden !important;
    }
}