#spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    transition-duration: 0.5s;
    opacity: 0;
    display: none;
}

#spinnerDiv {
    width: 100%;
    height: 100%;
}

.lds-ripple {
    margin-top: 25%;
}