#header {
    height: 4em;
    background-color: black;
    color: white;
    width: 100%;
}

#header ul {
    margin: 0;
    padding: 0 0 0 25%;
}

#header ul li {
    list-style: none;
    display: inline-block;
    padding: 25px 15px 25px 15px;
    font-variant: small-caps;
}

#headerLogo {
    height: 1.5em;
    position: absolute;
    left: 3%;
    top: 3%;
}

#headerIcons {
    float: right;
    padding: 15px;
    display: none;
}

#search {
    height: 1em;
    margin: 0 2em 0 2em;
}

#hamburger {
    height: 1em;
}

@media only screen and (max-width: 750px) {
    #header ul li {
        display: none;
    }
    #headerIcons {
        display: block;
    }
}