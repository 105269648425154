#navbar {
    margin: 0 auto;
    padding: 0;
    height: 10em;
    width: 100%;
    background-color: white;
    z-index: 100;
    color: black;
}

#navbar {
    align-content: center;
}

.navbarLogo:hover {
    cursor: pointer;
}

#navbar ul {
    align-content: center;
    padding: 0;
    margin: 0 auto;
}

#navbar ul li {
    list-style: none;
    display: inline-block;
    margin: 0 1em 0 1em;
    padding: 3px 1em 3px 1em;
    background-color: rgb(240,240, 240);
    border-radius: 20px;
    font-size: 16px;
    transition-duration: 0.25s;
}

#navbar #navSubtopic {
    font-size: 13px;
    display: none;
}

#navbar #seperator {
    margin: 0;
    padding: 0;
    background-color: rgba(0,0,0,0);
    display: none;
}

#navbar ul li:hover {
    background-color: rgb(202, 88, 88);
    cursor: pointer;
    color: white;
    transition-duration: 0.25s;
}

.active {
    background-color: rgb(202, 100, 100) !important;
    color: white;
}

.active:hover {
    background-color: rgb(202, 88, 88) !important;
    color: white;
}

.navbarLogo {
    width: 12em;
    position: absolute;
    left: 3%;
}

@media only screen and (max-width: 920px) {
    #navbar ul li {
        margin-top: 7em;
    }

    #navbar ul {
        margin: 0;
        width: 100%;
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    #navbar ul li {
        font-size: 12px;
        margin: 0 0.5em 0 0.5em;
        margin-top: 8.5em;
    }
}