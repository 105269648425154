#topicsSection {
    display: flex;
}

#topicSectionDescription{
    background-color: white;
    height: fit-content;
    padding: 1em 1em 6em 1em;
    margin-right: 1em;
    color: black;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#resultsSection {
    display: none;
}

.topicsHeader {
    margin: 1em 0 1em 0;
}

.topicDiv {
    width: 25%;
    height: 25vh;
    display: inline-block;
    text-align: center;
    margin-bottom: 4em;
}

/* Shrunk laptop screen */
@media only screen and (max-width: 991px) {
    .topicDiv {
        margin-bottom: 2em;
        height: 20vh;
    }
    .topicDiv .topic {
        font-size: 0.8em !important;
    }
}

/* Windows computer screen */
@media only screen and (min-width: 1600px) and (max-height: 900px) {
    .topicDiv {
        margin-bottom: 6em;
    }
}

/* iPad */
@media only screen and (max-width: 800px) {
    .topicDiv {
        margin-bottom: 1em;
        height: 15vh;
    }

    .topicDiv .topic {
        font-size: 0.75em !important;
    }
    .topicDescriptionDiv {
        height:55vh !important;
        margin-bottom: 1em !important;
        margin-right: 3em !important;
        margin-top: 1em !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
    }
    #TopicDescriptionBottomBox {
        width: 100%;    
        padding: 0em 1em 7em 0em;
    }
}

/* iPad pro */
@media only screen and (max-width: 1024px) and (max-height: 1366px) {
    .topicDiv {
        margin-bottom: 1em;
        height: 20vh;
    }
    .topicDescriptionDiv {
        margin-left: 3em !important;
    }
}

/* iPhone X */
@media only screen and (max-width: 415px) and (max-height: 812px) {
    .topicDiv {
        margin-bottom: 4em;
        height: 23vh;
        width: 50%;
    }
    #topicsSection .topic {
        font-size: 0.6em;
    }
    #topicsSection {
        padding: 0 !important;
    }

    #topicSectionDescription {
        padding: 1em 1em 0em 1em !important;
    }

    #topicsText {
        margin-bottom: 0em !important;
    }
    .topicDescriptionDiv {
        border-radius: 15px !important;
        margin-bottom: 1em !important;
        margin-right: 2em !important;
        margin-left: 2em !important;
        height: 25vh !important;
        border-radius: 0;
        background: white !important;
    }
    .topicsHeader {
        margin: 0.5em 0 0.5em 0 !important;
    }

    #TopicsTopBoxContainer {
        margin-top: 1em !important;
    }

    #TopicDescriptionBottomBox {
        width: 100% !important;    
        padding: 0em 1em 3em 0em !important;
    
    }
    
}

.topicBG {
    width: 85%;
    z-index: -1;
}

.topicBG:hover {
    cursor: pointer;
}

#resultsSection .topicBG {
    width: 12em;
    margin: 0;
    padding: 0;
    display: auto;
    text-align: center;
    transform: rotate(-10deg);
}

#resultsSection .topic {
    text-align: center;
    margin: 0 auto;
    width: 30%;
    left: 50%;
    transform: translateX(-50%) !important;
    font-size: 1.5em;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
}

@media only screen and (max-width: 1000px) {
    #resultsSection .topic {
        width: 66.6666%;
        font-size: 1.3em;
    }
}

#topicsSection .topic:hover {
    cursor: pointer;
}

#putElement {
    text-align: center;
}

.topicIcon {
    width: 85%;
}

.subtopicsRow {
    margin-top: 5em;
}

.resultsRow {
    height: 85vh;
    overflow: hidden;
    text-overflow: ellipses;
    margin-top: 3em;
}

#results {
    height: 75vh;
    overflow: auto;
}

.result {
    background-image: url(../../images/Card_Result_Active.svg);
    background-size: cover;
    background-repeat: none;
    display: inline-block;
    margin: 0.75em 0.5em 0.75em 0.5em;
    padding: 55px 25px 0 40px;
    color: black;
    height: 270px;
    width: 250px;
    vertical-align: top;
}

@media only screen and (max-width: 812px) and (min-width: 768px) {
    #results {
        text-align: center !important;
    }
}

@media only screen and (max-width: 533px) {
    #results {
        text-align: center !important;
    }
    #topicsSection {
        margin-top: 0em;
        margin-bottom: 3em;
    }
    .topicDescriptionDiv {
        border-radius: 15px !important;
        margin-bottom: 1em !important;
        margin-right: 2em !important;
        margin-left: 2em !important;
        height: 25vh !important;
        border-radius: 0;
        background: white !important;
    }
    .topicsHeader {
        margin: 0.5em 0 0.5em 0 !important;
    }
    #tag0Container {
        margin-left: 2em !important;
    }
    
}

.result:hover {
    cursor: pointer;
}

.standard-serial {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.abstract-container {
    height: 7.5em;
    overflow: auto;
    text-overflow: ellipsis;
}

#subtopicNavigationContainer {
    background-image: url('../../images/bkgn_generic_top.png');
    padding-top: 1em;
}

#chosenTopicImg {
    width: 7em;
    height: 7em;
    background-color: rgb(235, 139, 115);
    margin: 0 auto;
    transform: rotate(-10deg);
    margin-top: 2em !important;
    border-radius: 10px;
}

.z {
    z-index: 1;
}

#chosenTopic {
    background-color: white;
    color: rgb(235, 139, 115);
    border-radius: 1.5em;
    padding: 5px 0 5px 0;
}

#topicList {
    list-style: none;
    padding: 0;
    margin: 5em 0 3em 0;
}

#topicList li {
    display: inline-block;
    margin: 0.35em 0.4em 0.35em 0.4em;
    padding: 0 1em 0 1em;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 15px;
    font-size: 18px;
    transition-duration: 1s;
}

#topicList li:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition-duration: 0.5s;
}

#mymodal {
    text-align: center;
    /* background-color: white; */
    background-image: (url(../../images/bg.jpg));
    background-size: cover;
}

#modalImg {
    /* position: absolute; */
    /* left: 50%;
    transform: translateX(-50%); */
    /* padding: 3em; */
}

.topic {
    position: absolute;
    width: 25%;
    font-size: 1em;
    font-weight: bold;
    padding: 0 2.5em 0 2em;
    /* top: 85%; */
    text-align: left; 
    transform: translateY(-125%) !important;
    /* display: contents; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis !important;   */
    /* overflow: hidden; */
}

.topicDescriptionDiv {
    background: url('../../images/Description_Box.svg');
    background-size: cover;
    background-repeat: none;
    height: fit-content;
    margin-top: 10vh;
}

#topicsText {
    margin-bottom: 3em;
}

.activeSubtopic {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: white;
}

@media only screen and (max-width: 767px) {
    #topicsHeader {
        margin-top: 0;
        margin-bottom: 0;
    }

    .topicDescriptionDiv {
        border-radius: 15px !important;
        margin-bottom: 1em !important;
        margin-right: 2em !important;
        margin-left: 1.5em !important;
        height: 25vh !important;
        border-radius: 0;
        background: white !important;
    }
    #tag0Container {
        margin-left: 0em !important;
    }
    #topicsSection {
        margin-left: 0em;
    }

}
#TopicDescriptionBottomBox {
    width: 100% !important;    
    padding: 0em 1em 7em 0em;

}
