#aboutSection {
    display: none;
}

#aboutSectionDescription {
    background-color: white;
    height: fit-content;
    padding: 1em 1em 6em 1em;
    margin-right: 1em;
    color: black;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.aboutRow {
    margin-top: 5em;
    margin-bottom: 3em;
}

#aboutContent {
    background-color: white;
    border-radius: 25px;
    color: black;
    text-align: left;
    padding: 1.5em;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

#aboutContent h4 {
    font-size: 1.15em;
    font-weight: 400;
    background-color: rgb(236, 236, 236);
    border-radius: 15px;
    padding: 20px;
}

#initialHeader {
    margin: 0 3em 2em 0;
}

#aboutContent p {
    font-size: 1em;
    font-weight: 300;
    padding: 0 10% 0 10%;
}

#aboutContent li {
    font-size: 1em;
    font-weight: 300;
}

#aboutContent ul {
    padding: 0 15% 0 15%;
}

.aboutHeader {
    margin: 2em 3em 2em 0;
}

#AboutTopBox {
    width: 100%;
    margin: 0;
    padding: 0;
}

#AboutTopBoxContainer {
    margin: 0;
    padding: 0;
    margin-left: 1em !important;
    margin-right: 3em !important;

}

.logoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10% 0 10%;
    flex-wrap: wrap;
}

.aboutLogos {
    width: 112px;
    height: 68px;
    margin: 8px;
}
/* iPhone X */
@media only screen and (max-width: 415px) and (max-height: 812px) {
    #aboutSectionDescription {
        margin-left: 0em !important;
        margin-bottom: 1em !important;
        height: 25vh !important;
    }
    #AboutTopBoxContainer {
        margin-top: 1em !important;
        margin-left: 1em !important;
        padding: 0em !important;
    }
    #DescriptionBottomBox {
        display: none !important;    
    }
    #topicsSection {
        margin-top: 1em;
        margin-bottom: 3em;
    }
}

/* iPad */
@media only screen and (max-width: 767px) {
    .aboutRow {
        margin-top: 1em;
        margin-bottom: 3em;
    }
    #AboutTopBoxContainer {
        margin-top: 1em !important;
        margin-left: 1em !important;
        padding: 0em !important;
    }
    #aboutSectionDescription {
        margin-left: 0em !important;
        margin-bottom: 1em !important;
        height: 25vh !important;
    }

}

/* iPad pro */
@media only screen and (max-width: 1024px) and (max-height: 1366px) {
    #aboutSectionDescription {
        margin-left: 1em !important;
        margin-bottom: 0em !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
    }
    #AboutSectionBox {
        margin-bottom: 0em !important;
    }
    #AboutTopBoxContainer {
        margin-right: 1em !important;
    }
    #DescriptionBottomBox {
        visibility: hidden !important;
    }
}