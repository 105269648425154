html, body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.App {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: url('./images/bkgn_generic.jpg') no-repeat scroll center top;
  background-size: cover;
  background-position-x: 0;
  background-position-y: 0;
  color: black;
  overflow: hidden;
}

h1 {
  font-weight: 400;
  font-size: 2em;
}

p {
  font-weight: 300;
  font-size: 1em;
}

.center {
  text-align: center;
}

#topicsSection {
  padding-top: 5em;
  /* height: 100vh; */
  padding-bottom: 5em;
}